.text-reset {
  padding-left: 5px;
}
.container {
  background: black;
  border-radius: 10px;
}
.mt-40 {
  margin-top: 40px !important;
}

/* 48em = 768px */
@media (max-width: 48em) {
  p,
  .small, pre {
    font-size: small !important;
    color: white !important;
  }
  .margin-icon {
    margin-bottom: -45px;
    margin-top: 15px;
  }
}
.text-center {
  top: 45px;
  position: relative;
  margin-top: 20px;
}
p,
  .small {
    color: white !important;
  }