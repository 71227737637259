// .main-menu {
//   position: fixed;
//   top: 30px;
//   left: 30px;
//   z-index: 500;
//   height: 50px;
//   border-radius: 25px;
//   overflow: hidden;
//   background: black;
//   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
//   transition: all 1s ease;
//   box-shadow: 0 0 25px rgb(0,140,255);
//   & > * {
//     float: left;
//   }

//   #menu-toggle-side {
//     display: block;
//     cursor: pointer;
//     opacity: 0;
//     z-index: 999;
//     margin: 0;
//     width: 50px;
//     height: 50px;
//     position: absolute;
//     top: 0;
//     left: 0;

//     &:checked ~ ul {
//       width: 350px;
//       background-position: 0px -50px;
//     }
//   }

//   .menu-list {
//     list-style-type: none;
//     margin: 0;
//     padding: 0 0 0 50px;
//     height: 50px;
//     width: 0px;
//     transition: 0.5s width ease;
//     background-image: url("https://i.imgur.com/3d0vJzn.png");
//     background-repeat: no-repeat;
//     background-position: 0px 0px;
//   }

//   .menu-list-li {
//     display: inline-block;
//     line-height: 50px;
//     width: 50px;
//     text-align: center;
//     margin: 0;

//   .menu-list-a{
//       font-size: 1.25em;
//       font-weight: bold;
//       color: white;
//       text-decoration: none;
//     }
//   }
// }
.logo {
  position: fixed;
  top: 1%;
  left: 1%;
  z-index: 500;
  border-radius: 25px;
  overflow: hidden;
  transition: all 1s ease;
}
body {
  margin: 0;
  font-family: Helvetica, sans-serif;
  background-color: #f4f4f4;
}

a {
  color: #000;
}

/* header */

.header {
  background:black;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
  position: fixed;
  width: 100%;
  z-index: 3;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background-color: transparent;
}

.header li a {
  display: block;
  padding: 20px 20px;
  border-right: 1px solid #000000;
  text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
  background-color: #000000;
}

.header .logo {
  display: block;
  float: left;
  font-size: 2em;
  text-decoration: none;
  padding-top: 0;
  padding-left: 20px;
}

/* menu */

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 33px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 240px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
  .header li {
    float: left;
  }
  .header li a {
    padding: 26px 30px;
  }
  .header .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
}
