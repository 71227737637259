$midnight: #2c3e50;
$clouds: #ecf0f1;
// General
body {
  color: $midnight;
  background: $clouds;
  padding: 0 1em 1em;
}
.acc {
  h1 {
    margin: 0;
    line-height: 2;
    text-align: center;
  }
  h2 {
    margin: 0 0 .5em;
    font-weight: normal;
  }
  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  // Layout
    .col {
      margin: 2%;
      margin-top: 100px;
    
    }
  /* Accordion styles */
  .tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 4px -2px rgba(0,0,0,0.5);
  }
  .tab {
    width: 100%;
    color: white;
    overflow: hidden;
    &-label {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      background: $midnight;
      font-weight: bold;
      cursor: pointer;
      /* Icon */
      &:hover {
        background: darken($midnight, 10%);
      }
      &::after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all .35s;
      }
    }
    &-content {
      max-height: 0;
      padding: 0 1em;
      color: $midnight;
      background: black;
      transition: all .35s;
    }
    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      background: $midnight;
      cursor: pointer;
      &:hover {
        background: darken($midnight, 10%);
      }
    }
  }
  
  // :checked
  input:checked {
    + .tab-label {
      background: darken($midnight, 10%);
      &::after {
        transform: rotate(90deg);
      }
    }
    ~ .tab-content {
      max-height: 150vh;
      padding: 1em;
    }
  }
  h6 {
    color: red;
  }
  .details {
    color: white;
  }
}