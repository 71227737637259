body {
  background-color: rgb(0, 0, 0) !important;
}

.modal-dialog {
  max-width: 70% !important;
  height: 70% !important;
}

.modal-header {
  border-bottom: none !important;
}

@media (max-width: 768px) {
  .modal-dialog {
    max-width: 100% !important;
    height: 100% !important;
  }
}
.carousel-caption {
  backdrop-filter: blur(2px) !important;
  background-color: rgb(0 0 0 / 50%) !important;
}
.carousel {
  top: 45px !important;
}

/* 48em = 768px */
@media (max-width: 48em) {
  .carousel {
    top: 65px !important;
  }
}
.bg-dark {
  --mdb-bg-opacity: 0 !important;
}
.hs-responsive-embed-youtube {
  padding-top: 0 !important;
}
.modal-content {
  background-color: #252424 !important;
}
.modal-footer {
  border: none !important;
}
.btn-close {
  background: transparent url('./assets/close.png') center/1em auto no-repeat !important;
  width: 2em !important;
  height: 2em !important;
}
.form-label, .modal-title {
  color: #ffffff !important;
}
input, textarea {
  color: #adadad !important;
}
.form-check-label{
  color: white !important;
}