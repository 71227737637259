.button-28 {
    appearance: none;
    background-color: rgb(255, 0, 0);
    border: none;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    min-height: 60px;
    min-width: 0;
    outline: none;
    padding: 16px 24px;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 200px;
    will-change: transform;
  }
  
  .button-28:disabled {
    pointer-events: none;
  }
  

  
  .button-28:active {
    box-shadow: none;
    transform: translateY(0);
  }
  .header-title {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.2;
    padding: 20px;
    color: white;
  }
  .header-title:hover{
    color: red;
    cursor:default;
  }
  .desc {
    padding-top: 10px;
    filter: drop-shadow(2.5px 2.5px 5px #000000);
  }